import React, { useContext, useEffect, useState } from "react";
// Icons
import SearchIcon from '@mui/icons-material/Search';

// Card
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

// Core
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';

import { MdSentimentVeryDissatisfied, MdOutlineSentimentNeutral, MdOutlineSentimentVerySatisfied, MdSentimentSatisfiedAlt } from 'react-icons/md'

import { AuthContext } from "../../context/Auth/AuthContext";

import { Hidden } from "@material-ui/core";

// Date
import 'date-fns';
import { format, parseISO, subDays } from 'date-fns';

// Securiy
import { Can } from "../../components/Can";
import { Button, CardContent, Stack, TextField } from "@mui/material";
import { TableAttendanceNote } from "./TableAttendanceNote";
import { TableTicketsAvaliation } from "./TableTicketsAvaliation";
import api from "../../services/api";
import { UserContainer } from "../../components/UserContainer";



const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 320,
  },
  fixedHeightVendedor: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 320,
  },

  fixedHeightNPS: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 640,
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 120,
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  media: {
    height: 0,
  },
  avatar1: { backgroundColor: "#1c6434", },
  avatar2: { backgroundColor: "#FFF000", },
  avatar3: { backgroundColor: "#FF0000", },
  avatar4: { backgroundColor: "#0000FF", },
  avatar5: { backgroundColor: "#C1C1C1", },
  aba: {
    flexGrow: 1,
    paddingBottom: 10,
  },
  dateFilter: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "#fafafa",
    borderRadius: 5,
  },
}));

const Dashboard2 = () => {

  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [dataInicial, setDataInicial] = useState(() => {
    return subDays(new Date(), 30)
  });
  const [dataFinal, setDataFinal] = useState(new Date());

  const [dataNPS, setDataNPS] = useState({})
  const [search, setSearch] = useState(false);
  const [selectedUser, setSelectedUser] = useState('todos');

  useEffect(() => {
    handleGetDataNPS();
  }, [])

  const handleChangeInitialDate = (e) => {
    if (e === '' || e.length === 11) return;
    setDataInicial(parseISO(e))
  };

  const handleChangeFinalDate = (e) => {

    if (e === '' || e.length === 11) return;

    setDataFinal(parseISO(e))

  };

  const handleGetDataNPS = async () => {

    try {
      const { data } = await api.get(`/dashboard-notes-nps?initialDate=${format(dataInicial, 'yyyy-MM-dd')}&finalDate=${format(dataFinal, 'yyyy-MM-dd')}`)
      setDataNPS(data)
    } catch (error) {
      console.log('deu erro => ', error)
    }

  };

  const handleSearch = () => {
    setSearch(!search)
  }



  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
          <Hidden only={["xs", "sm"]}>
            <Can
              role={user.profile}
              perform="dashboard-chart:showview"
              yes={() => (
                <>

                  <Grid item xs={3}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className={classes.avatar4} >
                            <MdOutlineSentimentVerySatisfied fontSize={28} />
                          </Avatar>
                        }
                        action={
                          <Typography component="h1" variant="h3" color="primary" paragraph>
                            {dataNPS?.npsGeral}%
                          </Typography>
                        }
                        title="NPS"
                        subheader="Avaliação  NPS"
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={3}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className={classes.avatar1} >
                            <MdSentimentSatisfiedAlt fontSize={28} />
                          </Avatar>
                        }
                        action={
                          <Typography component="h1" variant="h3" color="primary" paragraph>
                            {dataNPS?.promotores?.percentual}%
                          </Typography>
                        }
                        title='Promotores'
                        subheader="Avaliações promotoras"
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={3}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className={classes.avatar2}  >
                            <MdOutlineSentimentNeutral fontSize={28} color="#fff" />
                          </Avatar>
                        }
                        action={
                          <Typography component="h1" variant="h3" color="primary" paragraph>
                            {dataNPS?.neutros?.percentual}%
                          </Typography>
                        }
                        title='Neutras'
                        subheader="Avaliações neutras"
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={3}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe" className={classes.avatar3}>
                            <MdSentimentVeryDissatisfied fontSize={28} />
                          </Avatar>
                        }
                        action={
                          <Typography component="h1" variant="h3" color="primary" paragraph>
                            {dataNPS?.detratores?.percentual}%
                          </Typography>
                        }
                        title="Detratores"
                        subheader="Avaliações Detratoras"
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Stack spacing={2} direction={'row'} sx={{ alignItems: 'center' }}>
                          <TextField
                            label="Data inicial"
                            sx={{ width: 170, minWidth: 170 }}
                            variant='outlined'
                            type='date'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={format(dataInicial, 'yyyy-MM-dd')}
                            onChange={(e) => handleChangeInitialDate(e.target.value)}
                          />

                          <TextField
                            label="Data final"
                            sx={{ width: 170, minWidth: 170 }}

                            variant='outlined'
                            type='date'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={format(dataFinal, 'yyyy-MM-dd')}
                            onChange={(e) => handleChangeFinalDate(e.target.value)}
                          />

                          <UserContainer
                            setUser={setSelectedUser}
                            user={selectedUser}
                          />

                          <Button
                            onClick={handleSearch}
                            sx={{
                              width: '370px',
                              height: '55px',
                              backgroundColor: '#1c6434',
                              '&:hover': {
                                backgroundColor: '#1c6434',
                              },
                            }} variant="contained" color="primary" size="large" startIcon={<SearchIcon />}>
                            Pesquisar
                          </Button>

                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <TableAttendanceNote
                          dataInicial={dataInicial}
                          dataFinal={dataFinal}
                          selectedUser={selectedUser}
                          search={search}
                        />
                      </CardContent>
                    </Card>
                  </Grid>


                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <TableTicketsAvaliation
                          dataInicial={dataInicial}
                          dataFinal={dataFinal}
                          selectedUser={selectedUser}
                          search={search}
                        />
                      </CardContent>
                    </Card>
                  </Grid>


                </>
              )}
            />

          </Hidden>

        </Grid>
      </Container>
    </div>
  )
};

export default Dashboard2;
