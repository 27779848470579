import React, { useContext, useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import { green } from "@material-ui/core/colors";
import {
	Button, 
	makeStyles,
	withStyles,
	Switch,
	Paper,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";

import { Can } from "../../components/Can";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n.js";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { systemVars } from "../../../package.json";

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupIcon from '@material-ui/icons/Group';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import MessageIcon from '@material-ui/icons/Message';
import DraftsIcon from '@material-ui/icons/Drafts';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "600px",
			flexWrap: "wrap",
		},
	  },
	container:{
		padding: "16px 24px",
	},
	mainPaper:{
		margin:"0 auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.up("sm")]: {
			minWidth: "600px",
		},
	},
	title: {
	},
	titleH2: {
		fontSize: "1.25rem",
		fontWeight: "500",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textCampaignsContainer: {
		width: "100%",
	},
	content: {
		padding: "0 24px 16px",
	},
	formActions: {
		flex: "0 0 auto",
		display: "flex",
		padding: "8px",
		alignItems: "center",
		justifyContent: "flex-end", 		
	},
	option: {
		display: "inline-flex",
		width: "100%",
		margin: "8px 0",
	},
	settingLabel: {
		padding: "5px 0",
	},
	settingOption: {
		marginLeft: "auto",
		minWidth: "128px",
	},
	switchOption: {
		marginLeft: "auto",
		minWidth: "40px",
	},
	icons:{
		marginLeft: 10,
		marginRight: 15,
	},
	settingTextField: {
		margin: "0 0 0 auto",
		maxWidth: "109px",
	},
	divider: {
		margin: "16px 0",
		border: "none",
		height: "1px",
		width: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.12)",
	},

}));

const IOSSwitch = withStyles((theme) => ({
	root: {
	  width: 1,
	  height: 24,
	  padding: 0,
	  margin: theme.spacing(0),
	  marginLeft:7,
	  marginRight: 10,
	  marginBottom:1,

	},
	switchBase: {
	  padding: 1,
	  '&$checked': {
		transform: 'translateX(16px)',
		color: theme.palette.common.white,
		'& + $track': {
		  backgroundColor: '#52d869',
		  opacity: 1,
		  border: 'none',
		},
	  },
	  '&$focusVisible $thumb': {
		color: '#52d869',
		border: '6px solid #ff0000',
	  },
	},
	thumb: {
	  width: 22,
	  height: 22,
	},
	track: {
	  borderRadius: 26 / 2,
	  border: `1px solid ${theme.palette.grey[400]}`,
	  backgroundColor: "#FF0000",
	  opacity: 1,
	  transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
  }))(({ classes, ...props }) => {
	return (
	  <Switch
		focusVisibleClassName={classes.focusVisible}
		disableRipple
		classes={{
		  root: classes.root,
		  switchBase: classes.switchBase,
		  thumb: classes.thumb,
		  track: classes.track,
		  checked: classes.checked,
		}}
		{...props}
	  />
	);
  });

const Settings = () => {
	const { user } = useContext(AuthContext);

	const classes = useStyles();
	const [settings, setSettings] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.checked ? "enabled" : "disabled";
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const handleChangeSettingOption = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;
		
		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};	
	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	const goToSupport = () => {
		window.open(systemVars.settingsSupportLink, '_blank');
	}

	const goToReleases = () => {
		window.open(systemVars.settingsReleases, '_blank');
	}

	return (
		<MainContainer>
			<MainHeader>
				<Title>{i18n.t("settings.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button
						variant="contained"
						color="primary"
						onClick={goToSupport}
						// disabled goToReleases
					>
						{i18n.t("settings.support")}
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={goToReleases}
						// disabled 
					>
						{i18n.t("settings.goToReleases")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>

			<Paper
			className={classes.mainPaper}
			variant="outlined"
			>
				<div className={classes.root}>  
					<div className={classes.container}>
						<div className={classes.title}>
							<h2 className={classes.titleH2}>{i18n.t("settings.settings.title")}</h2>
						</div>
						<div className={classes.divider}></div>
						
						<div className={classes.content}>
							<div className={classes.option}>
								<IOSSwitch
									className={classes.switchOption}
									id="userCreation-setting"
									disabled
									name="userCreation"
									checked={settings && settings.length > 0 && getSettingValue("userCreation") === "enabled"}
									onChange={handleChangeSetting}
									>
								</IOSSwitch>
								<PersonAddIcon className={classes.icons}/>
								<Tooltip title={i18n.t("settings.settings.userCreation.note")}>
									<Typography variant="body1">
										<span className={classes.settingLabel}>
											{i18n.t("settings.settings.userCreation.name")}
										</span>
									</Typography>
								</Tooltip>
							</div>
							<div className={classes.option}>
								<IOSSwitch
									className={classes.switchOption}
									id="sideMenu-setting"
									name="sideMenu"
									checked={settings && settings.length > 0 && getSettingValue("sideMenu") === "enabled"}
									onChange={handleChangeSetting}
									>
								</IOSSwitch>
								<MenuOutlinedIcon className={classes.icons}/>
								<Tooltip title={i18n.t("settings.settings.sideMenu.note")}>
									<Typography variant="body1">
										{i18n.t("settings.settings.sideMenu.name")}
									</Typography>
								</Tooltip>
							</div>
							<div className={classes.option}>
								<IOSSwitch
									className={classes.switchOption}
									id="call-setting"
									name="call"
									checked={settings && settings.length > 0 && getSettingValue("call") === "enabled"}
									onChange={handleChangeSetting}
									>
								</IOSSwitch>
								<PhoneDisabledIcon className={classes.icons}/>
								<Tooltip title={i18n.t("settings.settings.call.note")}>
									<Typography variant="body1">
										{i18n.t("settings.settings.call.name")}
									</Typography>
								</Tooltip>	
							</div>
							<div className={classes.option}>
									<IOSSwitch
										className={classes.switchOption}
										id="checkMsgIsGroup-setting"
										name="checkMsgIsGroup"
										checked={settings && settings.length > 0 && getSettingValue("checkMsgIsGroup") === "enabled"}
										onChange={handleChangeSetting}
										>
									</IOSSwitch>
									<GroupIcon className={classes.icons}/>
									<Tooltip title={i18n.t("settings.settings.checkMsgIsGroup.note")}>
										<Typography variant="body1">
											{i18n.t("settings.settings.checkMsgIsGroup.name")}
										</Typography>
									</Tooltip>	
							</div>
							<div className={classes.option}>
								<IOSSwitch
									className={classes.switchOption}
									id="transfTicket-setting"
									name="transfTicket"
									checked={settings && settings.length > 0 && getSettingValue("transfTicket") === "enabled"}
									onChange={handleChangeSetting} 
									>
								</IOSSwitch>
								<MessageIcon className={classes.icons} />
								<Tooltip title={i18n.t("settings.settings.transfTicket.note")}>
									<Typography variant="body1">
										{i18n.t("settings.settings.transfTicket.name")}
									</Typography>
								</Tooltip>	
							</div>
							<div className={classes.option}>
								<IOSSwitch
									className={classes.switchOption}
									id="isTimeNewCreateTicket-setting"
									name="isTimeNewCreateTicket"
									checked={settings && settings.length > 0 && getSettingValue("isTimeNewCreateTicket") === "enabled"}
									onChange={handleChangeSetting} 
									>
								</IOSSwitch>
								<MessageIcon className={classes.icons} />
								<Tooltip title={i18n.t("settings.settings.isTimeNewCreateTicket.note")}>
									<Typography variant="body1">
										{i18n.t("settings.settings.isTimeNewCreateTicket.name")}
									</Typography>
								</Tooltip>	
								<Select
									margin="dense"
									variant="outlined"
									native
									id="timeCreateNewTicket-setting"
									name="timeCreateNewTicket"
									value={
										settings && settings.length > 0 && getSettingValue("timeCreateNewTicket")
									}
									className={classes.settingOption}
									onChange={handleChangeSettingOption}
								>
									<option value="10">
										{i18n.t("settings.settings.timeCreateNewTicket.options.10")}
									</option>
									<option value="30">
										{i18n.t("settings.settings.timeCreateNewTicket.options.30")}
									</option>
									<option value="60">
										{i18n.t("settings.settings.timeCreateNewTicket.options.60")}
									</option>
									<option value="300">
										{i18n.t("settings.settings.timeCreateNewTicket.options.300")}
									</option>
									<option value="1800">
										{i18n.t("settings.settings.timeCreateNewTicket.options.1800")}
									</option>
									<option value="3600">
										{i18n.t("settings.settings.timeCreateNewTicket.options.3600")}
									</option>
									<option value="7200">
										{i18n.t("settings.settings.timeCreateNewTicket.options.7200")}
									</option>
									<option value="21600">
										{i18n.t("settings.settings.timeCreateNewTicket.options.21600")}
									</option>
									<option value="43200">
										{i18n.t("settings.settings.timeCreateNewTicket.options.43200")}
									</option>
								</Select>
							</div>
							<div className={classes.option}>
									<IOSSwitch
										className={classes.switchOption}
										id="notificationGroupNewTicket-setting"
										name="notificationGroupNewTicket"
										checked={settings && settings.length > 0 && getSettingValue("notificationGroupNewTicket") === "enabled"}
										onChange={handleChangeSetting}
										>
									</IOSSwitch>
									<DraftsIcon className={classes.icons}/>
									<Tooltip title={i18n.t("settings.settings.notificationGroupNewTicket.note")}>
										<Typography variant="body1">
											{i18n.t("settings.settings.notificationGroupNewTicket.name")}
										</Typography>
									</Tooltip>	
							</div>
							<div className={classes.option}>
									<IOSSwitch
										className={classes.switchOption}
										id="ticketHistoryPull-setting"
										name="ticketHistoryPull"
										checked={settings && settings.length > 0 && getSettingValue("ticketHistoryPull") === "enabled"}
										onChange={handleChangeSetting}
										>
									</IOSSwitch>
									<DraftsIcon className={classes.icons}/>
									<Tooltip title={i18n.t("settings.settings.ticketHistoryPull.note")}>
										<Typography variant="body1">
											{i18n.t("settings.settings.ticketHistoryPull.name")}
										</Typography>
									</Tooltip>	
							</div>
							<div className={classes.option}>
									<IOSSwitch
										className={classes.switchOption}
										id="lastTicketLinkToUser-setting"
										name="lastTicketLinkToUser"
										checked={settings && settings.length > 0 && getSettingValue("lastTicketLinkToUser") === "enabled"}
										onChange={handleChangeSetting}
										>
									</IOSSwitch>
									<EmojiPeopleIcon className={classes.icons}/>
									<Tooltip title={i18n.t("settings.settings.lastTicketLinkToUser.note")}>
										<Typography variant="body1">
											{i18n.t("settings.settings.lastTicketLinkToUser.name")}
										</Typography>
									</Tooltip>	
							</div>
							<div className={classes.option}>
									<IOSSwitch
										className={classes.switchOption}
										id="acceptTicketSendMessageUserId-setting"
										name="acceptTicketSendMessageUserId"
										checked={settings && settings.length > 0 && getSettingValue("acceptTicketSendMessageUserId") === "enabled"}
										onChange={handleChangeSetting}
										>
									</IOSSwitch>
									<DraftsIcon className={classes.icons}/>
									<Tooltip title={i18n.t("settings.settings.acceptTicketSendMessageUserId.note")}>
										<Typography variant="body1">
											{i18n.t("settings.settings.acceptTicketSendMessageUserId.name")}
										</Typography>
									</Tooltip>									
							</div>
						</div>
						<Can
							role={user.profile}
							perform="settings-super-items:view"
							yes={() => (
							<>
								{
								/*
								<div className={classes.divider}></div>
								<Typography variant="body1">
									<span className={classes.titleH3}>Notificação</span>
								</Typography>
								<Typography variant="body1">
									Selecione um Grupo que receberá a notificação quando um novo contato entra no atendimento. Observação: o numero da conexão padrão deverá ser membro do grupo.
								</Typography>
								<div className={classes.option}>
								<Tooltip title={i18n.t("settings.settings.campaignCreation.note")}>
										<Typography variant="body1">
										<span className={classes.titleH3}>Grupo que será notificação: </span>
										</Typography>
									</Tooltip>
									<Select
										margin="dense"
										variant="outlined"
										native
										id="campaignCreation-setting"
										name="campaignCreation"
										value={
											settings && settings.length > 0 && getSettingValue("campaignCreation")
										}
										className={classes.settingOption}
										onChange={handleChangeSetting}
									>
										<option value="enabled">
											{i18n.t("settings.settings.options.enabled")}
										</option>
										<option value="disabled">
											{i18n.t("settings.settings.options.disabled")}
										</option>
									</Select>
								</div>*/}
								<div className={classes.divider}></div>
								<Typography variant="body1">
									<span className={classes.titleH3}>{i18n.t("settings.settings.limits.title")}</span>
								</Typography>
								<Typography variant="body1">
									{i18n.t("settings.settings.limits.description")}
								</Typography>
								<div className={classes.dividerSilent}></div>
								<div className={classes.option}>
									<Typography variant="body1" >
										<span className={classes.settingLabel}>{i18n.t("settings.settings.limits.evaluationPeriod")}</span>
									</Typography>
									<TextField
										margin="dense"
										variant="outlined"
										id="evaluationPeriod-setting"
										name="evaluationPeriod"
										value="15/08/22"
										className={classes.settingTextField}
										disabled
									/>
								</div>								
								<div className={classes.option}>
									<Typography variant="body1" >
										<span className={classes.settingLabel}>{i18n.t("settings.settings.limits.maxUsers")}</span>
									</Typography>
									<TextField
										margin="dense"
										variant="outlined"
										id="maxUsers-setting"
										name="maxUsers"
										value={
											settings && settings.length > 0 && getSettingValue("maxUsers")
										}
										className={classes.settingTextField}
										disabled
									/>
								</div>
								<div className={classes.option}>
									<Typography variant="body1">
										<span className={classes.settingLabel}>{i18n.t("settings.settings.limits.maxConnections")}</span>
									</Typography>
									
									<TextField
										margin="dense"
										variant="outlined"
										id="maxConnections-setting"
										name="maxConnections"
										value={
											settings && settings.length > 0 && getSettingValue("maxConnections")
										}
										className={classes.settingTextField}
										disabled
									/>
								</div>
							</>
							)}
						/>
					</div>
				</div>
			</Paper>

		</MainContainer>
			
	);
};

export default Settings;