import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, } from '@mui/material';
import { MenuProps } from './MenuProps';
import api from '../../services/api';


export const UserContainer = (props) => {

  const { setUser, user } = props;

  const [listUsers, setListUsers] = useState([]);

  useEffect(() => {
    getListUsers();
  }, [])

  const getListUsers = async () => {
    try {
      const { data } = await api.get('/users');
      setListUsers(data.users);
    } catch (error) {

      console.log('deu erro => ', error)
    }
  };

  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };



  return (
    <FormControl fullWidth sx={{ minWidth: 170, }}>
      <InputLabel id="demo-simple-select-label" >Usuarios</InputLabel>
      <Select
        sx={{ maxHeight: '51px', height: '51px' }}
        value={user}
        label="Módulo"
        MenuProps={MenuProps}
        onChange={handleChangeUser}
      >
        <MenuItem sx={{ transition: '.2s', '&:hover': { backgroundColor: '#afb1f3c7', }, }} value={'todos'}>
          TODOS
        </MenuItem>
        {listUsers.map((user, index) => (
          <MenuItem key={index}
            sx={{ transition: '.2s', '&:hover': { backgroundColor: '#afb1f3c7', }, }}
            value={user?.id}>
            {user?.name}
          </MenuItem>
        ))}


      </Select>
    </FormControl>

  );
}
