const rules = {
	user: {
		static: [
			//Tickets
			"ticket-options:transferWhatsapp",
			//View Menu
			"drawer:tickets",
			"drawer:contacts",
			"drawer:quickAnswers",
		],
	},

	supervisor: {
		static: [
			//Tickets
			"tickets-manager:showall",
			"ticket-options:transferWhatsapp",
			"ticket-options:reopenTicket",
			//User Modal
			"user-modal:editQueues",
			//View Menu
			"drawer:tickets",
			"drawer:contacts",
			"drawer:quickAnswers",
			"drawer:queues",
			"drawer-items:management",
			"drawer-items:supervisor",
		],
	},

	admin: {
		static: [
			// Dashboard
			"dashboard-chart:showview",
			//Connections
			"connections:delete",
			//Contacts
			"contacts-page:deleteContact",
			"contacts-page:importContact",
			//Settings
			"settings-dev-items:view",
			"settings-super-items:view",
			//Tickets
			"tickets-manager:showall",
			"ticket-options:transferWhatsapp",
			"ticket-options:deleteTicket",
			"ticket-options:returnTicket",
			"ticket-options:reopenTicket",
			//User Modal
			"user-modal:editProfile",
			"user-modal:editQueues",
			//View Menu
			"drawer:dashboard",
			"drawer:tickets",
			"drawer:contacts",
			"drawer:quickAnswers",
			"drawer:queues",
			"drawer:campaigns",
			"drawer:users",
			"drawer:adm",
			"drawer:nps",
			"drawer:connections",
			"drawer:settings",
			"drawer-items:management",
			"drawer-items:supervisor",
			"drawer-items:admin",
		],
	},

	super: {
		static: [
			// Dashboard
			"dashboard-chart:view",
			"dashboard-chart:showview",
			"dashboard-chart:showview",
			//Connections
			"connections:delete",
			"connections:renew",
			//Contacts
			"contacts-page:deleteContact",
			"contacts-page:importContact",
			"contacts-page:exportContact",
			"contacts-page:bloquedContact",
			//Settings
			"settings-dev-items:view",
			"settings-super-items:view",
			"settings-super-items:edit",
			//Tickets
			"tickets-manager:showall",
			"ticket-options:transferWhatsapp",
			"ticket-options:deleteTicket",
			"ticket-options:returnTicket",
			"ticket-options:reopenTicket",
			//User Modal
			"user-modal:editProfile",
			"user-modal:editQueues",
			//Tags
			"tag:delete",
			//View Menu
			"drawer:dashboard",
			"drawer:tickets",
			"drawer:contacts",
			"drawer:quickAnswers",
			"drawer:schedules",
			"drawer:tags",
			"drawer:campaigns",
			"drawer:shippingReport",
			"drawer:settingsMessage",
			"drawer:queues",
			"drawer:connections",
			"drawer:users",
			"drawer:report",
			"drawer:settings",
			"drawer:api",
			"drawer:super",
			"drawer-items:management",
			"drawer-items:supervisor",
		]
	}
};

export default rules;