import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Hidden from "@material-ui/core/Hidden";
import { Can } from "../Can";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 3,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
				userName: user?.name,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<Can
				role={user.profile}
				perform="ticket-options:reopenTicket"
				yes={() => (
					<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
									)}
			/>

			)}
			{ticket.status === "open" && (
				<>
					<Hidden only={["xs"]}>
							<Can
								role={user.profile}
								perform="ticket-options:returnTicket"
								yes={() => (
    						<ButtonWithSpinner
    							loading={loading}
    							variant="contained"
    							color="secondary"
    							onClick={e => handleUpdateTicketStatus(e, "pending", null)}
    							className=""
    						>
    							<Replay /> {/*i18n.t("messagesList.header.buttons.return")*/}
    						</ButtonWithSpinner>
                          )}
              />
						<ButtonWithSpinner
							loading={loading}
							variant="contained"
							size="medium"
							color="primary"
							onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
						>
							<CloseRoundedIcon /> {/*i18n.t("messagesList.header.buttons.resolve")*/}
						</ButtonWithSpinner>
					</Hidden>
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					<DoneRoundedIcon /> {/*i18n.t("messagesList.header.buttons.accept")*/}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;
