import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DomainIcon from '@material-ui/icons/Domain';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Edit from '@material-ui/icons/Edit';
import { TagsContainer } from "../TagsContainer";
import CopyToClipboard from "../CopyToClipboard";
import ModalImageContatc from "./ModalImage";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "1px 0px 1px 1px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	contactHeader: {
		display: "flex",
		padding: 5,
		flexDirection: "column",
		alignItems: "left",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, loading }) => {
	const classes = useStyles();
	const [modalOpen, setModalOpen] = useState(false);
//	const [selectedTagIds, setSelectedTagIds] = useState([]);
	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton onClick={handleDrawerClose}>
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center"}}>
					{i18n.t("contactDrawer.header")}
				</Typography>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					
					<ListItem
						dense
					>
						<ListItemAvatar>
							<ModalImageContatc imageUrl={contact.profilePicUrl} />
						</ListItemAvatar>
						<ListItemText primary={<WhatsAppIcon /> && contact.name} secondary={contact.number} />
						<ListItemText
							disableTypography
							primary={
								<span className={classes.contactNameWrapper}>
									<IconButton
										size="small"
										onClick={() => setModalOpen(true)}
									>
										<Edit />
									</IconButton>
									<CopyToClipboard content={contact.number} color="secondary" />
								</span>
							}
						/>
						
						</ListItem>

					<Paper className={classes.contactHeader}>
							<InputLabel><MailOutlineIcon /> <Link href={`mailto:${contact.email}`}>{contact.email}</Link></InputLabel>
							<InputLabel><DomainIcon /> {contact.entity}</InputLabel>
							<InputLabel><AssignmentIndIcon /> {contact.sector}</InputLabel>
					</Paper>
						<TagsContainer contact={contact} className={classes.contactTags}/>
					<Paper square variant="outlined" >
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>
						<Typography variant="subtitle1">
							{i18n.t("contactDrawer.extraInfo")}
						</Typography>
						{contact?.extraInfo?.map(info => (
							<Paper
								key={info.id}
								square
								variant="outlined"
								className={classes.contactExtraInfo}
							>
								<InputLabel>{info.name}</InputLabel>
								<Typography component="div" noWrap style={{ paddingTop: 2 }}>
									<MarkdownWrapper>{info.value}</MarkdownWrapper>
								</Typography>
							</Paper>
						))}
					</Paper>
				</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
