import React from 'react';
import Chart from 'react-apexcharts';
import {ResponsiveContainer} from "recharts";
import useDashWeek from '../../hooks/useDashWeek';
import Title from "./Title";

const Chart2 = () => {

  const datenow =  new Date();

  var startWeek = (new Date(datenow.setDate((datenow.getDate() - datenow.getDay())))).toLocaleDateString('sv');
  var endWeek = (new Date(datenow.setDate(((datenow.getDate() - datenow.getDay()) + 6)))).toLocaleDateString('sv');

	const GetTicketsTotalWeek = () => {
		const { series } = useDashWeek({startWeek, endWeek});
		return { series };
    /*return {};*/
	}

  const AuxuseHook = (GetTicketsTotalWeek(startWeek,endWeek));
  const SeriesFinal = Object.values(Object.values(AuxuseHook)[0])[0];
  let series = [];
  
  for(const key in SeriesFinal){
    series = SeriesFinal[key];
  }
  const options = {xaxis: {categories: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']}};
  return (
    <React.Fragment>
      <Title>{`Atendimento da Semana`}</Title>
      <ResponsiveContainer>
        <Chart options={options} series={series} type="area" />
      </ResponsiveContainer>
    </React.Fragment>
  );
}

export default Chart2;
