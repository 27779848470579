import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

import { WhatsApp} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		flex: "none",
		marginLeft: "12px",
		marginRight: "-12px"
	},
	viewButton: {
		position: "absolute",
		left: "60%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	userTag: {
		position: "absolute",
		marginRight: 35,
		right: 10,
		bottom: 9,
		background: theme.palette.primary.main,
		color: "#ffffff",
		border:"1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
	infoTags: {
		display: "flex",
		justifyContent: "space-between",
		alignSelf: "center",
		marginLeft: "auto",
		fontSize: "0.75em",
	},

	infoTag: {
		color: theme.palette.primary.main,
		border: "1px solid",
		borderColor: theme.palette.primary.main,
		padding: "2px 6px 1px",
		marginLeft: 5,
		borderRadius: "10vh",
	},

	infoTagAlternate: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		border: "1px solid",
		borderColor: theme.palette.primary.main,
		padding: "2px 6px 1px",
		marginLeft: 5,
		borderRadius: "10vh",
	},

	infoTagSecondary: {
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		padding: "2px 6px 1px",
		marginLeft: 5,
		transform: "scale(1) translate(0px, 1px)",
		borderRadius: "10px",
	},	
	infoTagIcon: {
		'& svg':{
			height: "0.65em",
			maxWidth: "0.75em",
			marginLeft: 5,
			color: theme.palette.primary.main,
			transform: "scale(1.3) translate(0px, 2px)",		
		},
	},
	iconConnect: {
		'& svg':{
			height: "0.65em",
			maxWidth: "0.75em",
			marginLeft: 5,
			color: theme.palette.primary.main,
			transform: "scale(1.3) translate(0px, 2px)",		
		},
	},
	infoTagIconAlternate: {
		'& svg':{
			height: "0.65em",
			maxWidth: "0.75em",
			marginLeft: 5,
			color: theme.palette.primary.main,
			transform: "scale(1.4) translate(0px, 2px)",		
		},
	}	
}));

const TicketListItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
				userName: user?.name,
				queueId: user?.queueId,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

	const handleOpenAcceptTicketWithouSelectQueue = () => {
		setAcceptTicketWithouSelectQueueOpen(true);
	};	

	return (
		<React.Fragment key={ticket.id}>
			<AcceptTicketWithouSelectQueue
				modalOpen={acceptTicketWithouSelectQueueOpen}
				onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
				ticketId={ticket.id}
			/>
				<ListItem
					dense
					button
					onClick={e => {
						if (ticket.status === "pending") return;
						handleSelectTicket(ticket.id);
					}}
					selected={ticketId && +ticketId === ticket.id}
					className={clsx(classes.ticket, {
						[classes.pendingTicket]: ticket.status === "pending",
					})}
				>
					<Tooltip
						arrow
						placement="right"
						title={ticket.queue?.name || i18n.t("ticketsList.items.queueless")}
					>
						<span
							style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
							className={classes.ticketQueueColor}
						></span>
					</Tooltip>
					<ListItemAvatar>
						<Avatar 
							style={{
								width: "50px",
								height: "50px",
								}}
							src={ticket?.contact?.profilePicUrl} />
					</ListItemAvatar>
					<ListItemText
						disableTypography
						primary={
							<span className={classes.contactNameWrapper}>
								<Typography
									noWrap
									component="span"
									variant="body2"
									color="textPrimary"
								>
									{ticket.whatsappId && (
										<div className={classes.iconConnect} title={i18n.t("ticketsList.connectionTitle") + ticket.whatsapp?.name}>{ticket.contact.name}<WhatsApp /></div>
									)}
								</Typography>
								{ticket.status === "closed" && (
									<Badge
										className={classes.closedBadge}
										overlap="rectangular"
										badgeContent={"❌"}
										color="default"
									/>
								)}
								{ticket.lastMessage && (
									<Typography
										className={classes.lastMessageTime}
										component="span"
										variant="body2"
										color="textSecondary"
									>
										{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
											<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
										) : (
											<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
										)}
									</Typography>
								)}
							</span>
						}
						secondary={
							<span className={classes.contactNameWrapper}>
								<Typography
									className={classes.contactLastMessage}
									noWrap
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{ticket.lastMessage ? (
										<MarkdownWrapper>{ticket.lastMessage.slice(0, 20) + (ticket.lastMessage.length > 20 ? " ..." : "")}</MarkdownWrapper>
									) : (
										<br />
									)}
								</Typography>
								<div className={classes.infoTags}>
									{ticket.userId && (
										<div className={classes.infoTagIcon} title={i18n.t("ticketsList.userTitle")+ticket.user?.name}>{/* {(ticket.user?.name ? ticket.user?.name: "")} */}<AccountBoxOutlinedIcon /></div>
									)}		
									{/*ticket.whatsappId && (
										<div className={classes.infoTagIcon} title={i18n.t("ticketsList.connectionTitle") + ticket.whatsapp?.name}><WhatsApp /></div>
									)*/}
									{ticket.unreadMessages !== 0 && (
										<div className={classes.infoTagSecondary} title={i18n.t("ticketsList.unreadMessages")}>{ticket.unreadMessages}</div>
									)}																
								</div>
							</span>
						}
					/>
					{(ticket.status === "pending" && ticket.queue !== null) && (
						<IconButton
						className={classes.acceptButton}
						color="primary"
						onClick={e => handleAcepptTicket(ticket.id)}
						loading={loading} >
							<DoneIcon />
						</IconButton>	
					)}
					{(ticket.status === "pending" && (ticket.queue === null || ticket.queue === undefined)) && (
						<IconButton
						className={classes.acceptButton}
						color="primary"
						onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
						>
							<VisibilityIcon />
						</IconButton>								
					)}
				</ListItem>
		

			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
