import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Dashboard2 from "../pages/Dashboard2";
import Login from "../pages/Login/";
import LoggedInLayout from "../layout";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import Settings from "../pages/Settings/";
import Signup from "../pages/Signup/";
import Super from "../pages/Super/";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import ShippingReport from "../pages/ShippingReport";
import Campaigns from "../pages/Campaigns/";
import Schedules from "../pages/Schedules";
import Tags from "../pages/Tags";
import Reports from "../pages/Reports";


import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";


const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route exact path="/dashboard2" component={Dashboard2} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
              <Route exact path="/settings" component={Settings} isPrivate />
              <Route exact path="/super" component={Super} isPrivate />
              <Route exact path="/queues" component={Queues} isPrivate />
              <Route exact path="/shippingReport" component={ShippingReport} isPrivate />
              <Route exact path="/campaigns" component={Campaigns} isPrivate />
              <Route exact path="/schedules" component={Schedules} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
              <Route exact path="/reports" component={Reports} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
