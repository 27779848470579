import React from 'react';
import Chart from 'react-apexcharts';
import {
  XAxis,
	ResponsiveContainer,
} from "recharts";
import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useDashMonth from '../../hooks/useDashMonth';

const Chart3 = () => {
  
  const mesBR = ['Janeiro', 'Fevereiro','Março','Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro','Dezembro'];//Array de Meses

	const GetDashMonth = () => {
		const { series } = useDashMonth({
		});
		return { series };
	}

  const AuxuseHook = (GetDashMonth());
  const SeriesFinal = Object.values(Object.values(AuxuseHook)[0])[0];
  let series = [];
  
  for(const key in SeriesFinal){
    series = SeriesFinal[key];
  }

  const options = {xaxis: {categories: mesBR}};
  return (
    <React.Fragment>
      <Title>{i18n.t("dashboard.charts3.title")}</Title>
      <ResponsiveContainer>
        <Chart 
          options={options} 
          series={series} 
          type="bar"
        >
        <XAxis dataKey="time" />  
        </Chart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

export default Chart3;
