import React, { useEffect, useState } from "react";
import openSocket from "socket.io-client";

import { green } from "@material-ui/core/colors";
import {
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Tooltip,
	Typography,
  } from '@material-ui/core';
import {
	CropFree,
	SignalCellularConnectedNoInternet2BarRounded,
	SignalCellularConnectedNoInternet0BarRounded,
	SignalCellular4BarRounded,
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import { Formik, Field } from "formik";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "600px",
			flexWrap: "wrap",
		},
	  },
	container:{
		padding: "16px 24px",
	},
	mainPaper:{
		margin:"0 auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.up("sm")]: {
			minWidth: "600px",
		},
	},
	title: {
	},
	titleH2: {
		fontSize: "1.25rem",
		fontWeight: "500",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textCampaignsContainer: {
		width: "100%",
	},
	contentDividers: {
		padding: "16px 24px",
		borderTop: "1px solid rgba(0,0,0,0.12)",
		borderBottom: "1px solid rgba(0,0,0,0.12)",
	},
	formActions: {
		flex: "0 0 auto",
		display: "flex",
		padding: "8px",
		alignItems: "center",
		justifyContent: "flex-end", 		
	},
	formControl: {
		minWidth: 120,
	},
	connectInfoItem: {
		justifyContent: "space-between",
		width: "100%",
	},
	connectionInfo: {
		alignItems: "center",
		display: "flex",
		float: "right",
		marginTop: "-5px",
		marginBottom: "-4px",
	},
	connectionInfoSpan: {
		opacity: "70%",
	},
  }));

const CustomToolTip = ({ title, content, children }) => {
	const classes = useStyles();

	return (
		<Tooltip
			arrow
			classes={{
				tooltip: classes.tooltip,
				popper: classes.tooltipPopper,
			}}
			title={
				<React.Fragment>
					<Typography gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography>{content}</Typography>}
				</React.Fragment>
			}
		>
			{children}
		</Tooltip>
	);
};

// Get hostname of the environment variable
const getHostname = (url) => {
	return new URL(url).hostname;
};
const env_host = getHostname(process.env.REACT_APP_BACKEND_URL);

//get propper port of the environment
let http = require('http');

const getPort = (host) => {
	if (host === 'localhost') {
		return 8080;
	} else {
		http = require('https');
		return '';
	}
}
const env_port = getPort(env_host);

const init = {
  host: env_host,
  path: '/api/new-message',
  port: env_port,
  method: 'POST',
  headers: {
    'content-type': 'application/json; charset=utf-8'
  }
};

const callback = function(response) {
  let result = Buffer.alloc(0);
  response.on('data', function(chunk) {
    result = Buffer.concat([result, chunk]);
  });
  
  response.on('end', function() {
    console.log(result.toString());
  });
};

async function whatsappCampaignSender(campaignContactNumber, campaignMsg, campaignWhatsappId) {
	// await api.post() // Do again in the future using post api for security
	const req = http.request(init, callback);
	const body = '{"campaignContactNumber":"'+ campaignContactNumber +
                    '","campaignMsg":"' + campaignMsg.replace(/\n/g, "\\n") +
                    '","campaignWhatsappId":' + campaignWhatsappId +
                 '}';
	await req.write(body);
	req.end();
};

const Campaign = () => {
	const classes = useStyles();
	const [inputs, setInputs] = useState({});
	
	const {whatsApps} = useWhatsApps();

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({...values, [name]: value}))
	  }
	
	const handleSubmit = (event) => {
		event.preventDefault();
		
		const contactsTextArea = inputs.contact.split('\n');
		contactsTextArea.forEach((contact) => {
			setTimeout(function() {
				whatsappCampaignSender(contact, inputs.message, inputs.id);
				},3000 + Math.floor(Math.random() * 10000))            
		  	});
		toast.success(i18n.t("campaignsModal.toasts.sending"));

	}
	
	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
		return () => {
			socket.disconnect();
		};
	}, []);

	const renderStatusToolTips = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{whatsApp.status === "DISCONNECTED" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					>
						<SignalCellularConnectedNoInternet0BarRounded color="secondary" />
					</CustomToolTip>
				)}
				{whatsApp.status === "OPENING" && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
				{whatsApp.status === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					>
						<CropFree />
					</CustomToolTip>
				)}
				{whatsApp.status === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellular4BarRounded style={{ color: green[500] }} />
					</CustomToolTip>
				)}
				{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					>
						<SignalCellularConnectedNoInternet2BarRounded color="secondary" />
					</CustomToolTip>
				)}
			</div>
		);
	};

	return (
		<MainContainer>
			<MainHeader>
				<Title>{i18n.t("campaignsModal.title.title")}</Title>
				<MainHeaderButtonsWrapper>
					{/* <Button
						variant="contained"
						color="primary"
						disabled
					>
						{i18n.t("campaignsModal.title.create")}
					</Button> */}
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Paper
			className={classes.mainPaper}
			variant="outlined"
			>
				<div className={classes.root}>  
					<div className={classes.container}>
						<div className={classes.title}>
							<h2 className={classes.titleH2}>{i18n.t("campaignsModal.form.title")}</h2>
						</div>
						<Formik
						
						>
							<form onSubmit={handleSubmit}>
								<div className={classes.contentDividers}>
									<div className={classes.textCampaignsContainer}>
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>{i18n.t("campaignsModal.form.id.title")}</InputLabel>
											<Field
												as={Select}
												label={i18n.t("campaignsModal.form.id.title")}
												name="id"
												autoFocus
												variant="outlined"
												margin="dense"
												className={classes.textField}
												fullWidth
												placeholder={i18n.t("campaignsModal.form.id.placeholder")}
												value={inputs.id || ""} 
												onChange={handleChange}
											>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id} className={classes.connectInfoItem}>
														{whatsapp.name}
														<div className={classes.connectionInfo}>
															<div className={classes.connectionInfoSpan}>
																{i18n.t("campaignsModal.form.connection.status")}
															</div>
															{renderStatusToolTips(whatsapp)}
														</div>
													</MenuItem>
												))}
											</Field>
										</FormControl>
									</div>

									<div className={classes.textCampaignsContainer}>
										<Field
											as={TextField}
											label={i18n.t("campaignsModal.form.contacts.title")}
											name="contact"
											variant="outlined"
											margin="dense"
											className={classes.textField}
											multiline
											minRows={5}
											fullWidth
											placeholder={i18n.t("campaignsModal.form.contacts.placeholder")}
											value={inputs.contact || ""} 
											onChange={handleChange}
											required={true}
										/>
									</div>
									
									<div className={classes.textCampaignsContainer}>
										<Field
											as={TextField}
											label={i18n.t("campaignsModal.form.message.title")}
											name="message"
											variant="outlined"
											margin="dense"
											className={classes.textField}
											multiline
											minRows={5}
											fullWidth
											placeholder={i18n.t("campaignsModal.form.message.placeholder")}
											value={inputs.message || ""} 
											onChange={handleChange}
											required={true}
										/>
									</div>
								</div>

								<div className={classes.formActions}>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										className={classes.btnWrapper}
										>
											{i18n.t("campaignsModal.buttons.submit")}
									</Button>
								</div>
							</form>
						</Formik>
					</div>
				</div>
			</Paper>
		</MainContainer>
	);
};

export default Campaign;