import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MdSentimentVeryDissatisfied, MdOutlineSentimentNeutral, MdSentimentSatisfiedAlt } from 'react-icons/md'
import { styled } from '@mui/material/styles';
import Title from '../../components/Title';
import api from '../../services/api';
import { format } from 'date-fns';


export const TableAttendanceNote = (props) => {

  const { dataInicial, dataFinal, selectedUser, search } = props;

  const [listAttendanceNote, setListAttendanceNote] = useState([]);

  useEffect(() => {
    handleGetNotesAttendances();
  }, [search])

  const getColorToLinearProgress = (value) => {

    if (value <= 69) {
      return 'error';
    } else if (value >= 70 && value <= 89) {
      return 'warning';
    } else {
      return 'success';
    }

  }

  const handleGetNotesAttendances = async () => {
    try {

      const { data } = await api.get(`/dashboard-notes-attendances?initialDate=${format(dataInicial, 'yyyy-MM-dd')}&finalDate=${format(dataFinal, 'yyyy-MM-dd')}&interval=todas&&attendanceId=${selectedUser}`)
      setListAttendanceNote(data);
    } catch (error) {
      console.log(' => ', error)
    }

  };

  return (
    <>
      <Title>Avaliação por funcionário</Title>
      <Divider />
      <TableContainer sx={{ marginBottom: 2 }}>
        <Table sx={{ minWidth: 650 }} size="small" >
          <TableHead sx={{ background: '#eee', height: 40, }}>
            <TableRow sx={{}}>

              <TableCell sx={{ textAlign: 'center' }} >Funcionário</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>Nota NPS &nbsp; </TableCell>
              <TableCell sx={{ textAlign: 'center', width: 300, }}>Detratores / Neutros / Promotores</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>Total de avaliações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {listAttendanceNote.map((row) => {

              return (
                <TableRow key={row.id}>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <strong>{row?.user}</strong>
                  </TableCell>

                  <TableCell sx={{ textAlign: 'center', justifyContent: 'flex-end' }}>
                    <Stack sx={{ position: 'relative', height: '100%', }}>
                      <div style={{ position: 'absolute', bottom: 0, left: 5, zIndex: 10, color: '#fff', fontWeight: 'bold' }}>{row?.npsGeneral}%</div>
                      <BorderLinearProgress variant="determinate" value={row?.npsGeneral} color={getColorToLinearProgress(row?.npsGeneral)} />
                    </Stack>
                  </TableCell>

                  <TableCell sx={{ textAlign: 'center' }}>
                    <Stack spacing={2} direction={'row'} justifyContent={'center'} >

                      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1} sx={{}}>
                        <MdSentimentVeryDissatisfied size={25} color={'#ff4000'} />
                        <strong>{row?.detratores?.percentual}%</strong>
                      </Stack>

                      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{}}>
                        <MdOutlineSentimentNeutral size={25} color={'#ffc800'} />
                        <strong>{row?.neutros?.percentual}%</strong>
                      </Stack>

                      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{}}>
                        <MdSentimentSatisfiedAlt size={25} color={'#00a00a'} />
                        <strong>{row?.promotores?.percentual}%</strong>
                      </Stack>

                    </Stack>
                  </TableCell>

                  <TableCell sx={{ textAlign: 'center', height: 25 }}>
                    <strong>{row?.totalAvaliacoes}</strong>
                  </TableCell>

                </TableRow>
              )

            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 5,
}));