import React from "react";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
 btnCard: {
  display: "inline",
  maxWidth: "345px",
  paddingTop: "5px",
  marginLeft: "15px",
},
}));



const ReportCard = ({name, titles, subheader, urls, dateStart, dateEnd}) => {
	const classes = useStyles();

  function myCard(){
    
  }

	return (
      <Button
      type="submit"
      color="primary"
      name={name}
      value={name}
      onClick={myCard}
      >
        <Card className={classes.btnCard}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}><VisibilityIcon /></Avatar>
          }
          title={titles}
          subheader={subheader}
          name={name}
          urls={urls}
        />
        </Card> 
      </Button>
	);
};

export default ReportCard;
