import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import Grid from "@material-ui/core/Grid";
import ReportCard from "../../components/ReportCard";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles((theme) => ({
  container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
  selectData: {
    marginLeft: 15,
    paddingTop: 0,
    paddingBottom: 5,
  },
  btnVisualizar: {
    display: "inline",
  },
  btnCard: {
      maxWidth: "360px",
      paddingTop: "5px",
      marginLeft: "15px",
      marginBottom: "15px",
      marginTop: "15px",
  },
}));

const ReportsData = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false);
  const [selectedDateStart, setSelectedDateStart] = React.useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date());

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>Relatório</Title>
      </MainHeader>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sm={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDateStart}
              onChange={handleDateChangeStart}
              KeyboardButtonProps={{'aria-label': 'change date',}}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDateEnd}
              onChange={handleDateChangeEnd}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={12}>
            <ReportCard name="NPSUser" dateStart={selectedDateStart} dateEnd={selectedDateEnd} titles="Net Promoter Score" subheader="por usuario" urls="/ReportNpsUser" className={classes.btnVisualizar} />
            <ReportCard name="NPSGeral" dateStart={selectedDateStart} dateEnd={selectedDateEnd}  titles="Net Promoter Score" subheader="Geral" urls="/ReportNps" className={classes.btnVisualizar} />        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ReportsData;
