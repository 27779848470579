import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { green } from "@material-ui/core/colors";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	makeStyles,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Switch,
	FormControlLabel
  } from '@material-ui/core';
import { 
	Visibility,
	VisibilityOff
} from '@material-ui/icons';

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { i18n } from "../../translate/i18n";
import QueueSelect from "../QueueSelect";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { systemVars } from "../../../package.json";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
		width: "100%",
	},

	btnWrapper: {
		position: "100%",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		// margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, `${i18n.t("validation.too_short")}`)
		.max(50, `${i18n.t("validation.too_long")}`)
		.required(`${i18n.t("validation.required")}`),
	password: Yup.string()
		.min(5, `${i18n.t("validation.too_short")}`)
		.max(50, `${i18n.t("validation.too_long")}`),
	email: Yup.string()
		.email(`${i18n.t("validation.invalid_email")}`)
		.required(`${i18n.t("validation.required")}`),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		state: false,
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);
	const {loading, whatsApps} = useWhatsApps();
	const startWorkRef = useRef();
	const endWorkRef = useRef();

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, whatsappId, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	const superUser = (mail) => {
		if(mail.split('@')[1] === systemVars.controllerDomain &&
		   mail.split('@')[0] !== 'admin') {
			   return (
				<MenuItem value="super">{i18n.t("userModal.form.profile.role.super")}</MenuItem>
			   );
		   }
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
										endAdornment: (
											<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword((e) => !e)}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
											</InputAdornment>
										)
										}}
										fullWidth
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editProfile"
										yes={() => (
											<>
												<FormControl
													variant="outlined"
													className={classes.formControl}
													margin="dense"
												>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile.title")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.profile.title")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required
													>
														{superUser(loggedInUser.email)}
														<MenuItem value="admin">{i18n.t("userModal.form.profile.role.admin")}</MenuItem>
														<MenuItem value="finance">{i18n.t("userModal.form.profile.role.finance")}</MenuItem>
														<MenuItem value="supervisor">{i18n.t("userModal.form.profile.role.supervisor")}</MenuItem>	
														<MenuItem value="user">{i18n.t("userModal.form.profile.role.user")}</MenuItem>
													</Field>
												</FormControl>
												<FormControlLabel
													control={
														<Field
															as={Switch}
															color="primary"
															name="state"
															checked={values.state}
														/>
													}
												/>
											</>
										)}
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.startWork.name")}
										type="time"
										ampm={false}
										defaultValue="08:00"
										inputRef={startWorkRef}
										InputLabelProps={{shrink: true,}}
										inputProps={{step: 600,}}
										fullWidth
										name="startWork"
										error={touched.startWork && Boolean(errors.startWork)}
										helperText={touched.startWork && errors.startWork}
										variant="outlined"
										margin="dense"
										className={classes.hora}
									/>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.endWork.name")}
										type="time"
										ampm={false}
										defaultValue="17:00"
										inputRef={endWorkRef}
										InputLabelProps={{shrink: true,}}
										inputProps={{step: 600,}}
										fullWidth
										name="endWork"
										error={touched.endWork && Boolean(errors.endWork)}
										helperText={touched.endWork && errors.endWork}
										variant="outlined"
										margin="dense"
										className={classes.hora}
									/>
								</div>								
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (!loading &&
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
											<Field
												as={Select}
												value={whatsappId}
												onChange={(e) => setWhatsappId(e.target.value)}
												label={i18n.t("userModal.form.whatsapp")}
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
												))}
											</Field>
										</FormControl>
									)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
