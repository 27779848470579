import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useDashWeek = ({
    startWeek,
    endWeek
}) => {
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState([{name: "Sem dados", data: []}]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchDataResponse = async() => {
                try {
                    const data = await api.get("/dashboardTicketweek", {
                        params: {
                            startWeek,
                            endWeek
                        },
                    })
                    setSeries(data)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            fetchDataResponse()
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [startWeek, endWeek])

    return { series, loading };
};

export default useDashWeek;