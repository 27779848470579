import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	Typography,
} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	hora: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: 150,
	  },
			option: {
				display: "inline-flex",
				width: "100%",
				height: "35px",
				borderRadius: "5px",
				marginLeft: theme.spacing(1),
				marginRight: theme.spacing(1),
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(1),
				paddingLeft: "8px",
			},
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(20, "Too Long!")
		.required("Required"),
});

export default React.memo(({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const initialState = {
		name: "",
		greetingMessage: "",
		farewellMessage: "",
		absenceMessage:"",
		ticketNpsMessage:"",
		isTicketNPS: true,
		timeClosedTicketInNps: null,
		startWork: "",
		endWork: "",
		startWeek: "",
		endWeek: "",
		isDefault: false,
		isOpeningHours: false,
		isGreetingMessage: false, 
		isFarewellMessage: false,
		isMon: true,
		isTue: true,
		isWed: true,
		isThu: true,
		isFri: true,
		isSat: false,
		isSun: false
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	//const [timeClosedTicketInNps, setTimeClosedTicketInNps] = useState(null);
	const startWorkRef = useRef(null);
	const endWorkRef = useRef(null);
	const startWeekRef = useRef(null);
	const endWeekRef = useRef(null);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);
				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		const whatsappData = { ...values, queueIds: selectedQueueIds};
		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						/*alert(JSON.stringify(values, null, 1));*/
						setTimeout(() => {
							handleSaveWhatsApp(values); 
							actions.setSubmitting(false);
						}, 1000);
					}}
				>
					{({ values, touched, errors, isSubmitting, handleChange }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label={i18n.t("whatsappModal.form.default.name")}
									/>
								</div>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>
								<div className={classes.multFieldLine}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isGreetingMessage"
												checked={values.isGreetingMessage}
											/>
										}
									/>	
									<div className={classes.title}>
										<h4 className={classes.titleH2}>Mensagens de Saudação
										<Tooltip title={i18n.t("whatsappModal.form.greetingMessage.title")}>
											<IconButton aria-label="isGreetingMessage">
												<HelpIcon />
											</IconButton>
										</Tooltip>
										</h4>
									</div>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.greetingMessage.name")}
										type="greetingMessage"
										multiline
										minRows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isFarewellMessage"
												checked={values.isFarewellMessage}
											/>
										}
									/>	
									<div className={classes.title}>
										<h4 className={classes.titleH2}>Mensagens de Despedida 
										<Tooltip title={i18n.t("whatsappModal.form.farewellMessage.title")}>
											<IconButton aria-label="isFarewellMessage">
												<HelpIcon />
											</IconButton>
										</Tooltip>
										</h4>
									</div>
								</div>								
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.farewellMessage.name")}
										type="farewellMessage"
										multiline
										minRows={5}
										fullWidth
										name="farewellMessage"
										error={
											touched.farewellMessage && Boolean(errors.farewellMessage)
										}
										helperText={
											touched.farewellMessage && errors.farewellMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isOpeningHours"
												checked={values.isOpeningHours}
											/>
										}
									/>	
									<div className={classes.title}>
										<h4 className={classes.titleH2}>Horario de Funcionamento
										<Tooltip title={i18n.t("whatsappModal.form.absenceMessage.title")}>
											<IconButton aria-label="absenceMessage">
												<HelpIcon />
											</IconButton>
										</Tooltip>
										</h4>
									</div>								
								</div>
								<FormControl component="fieldset">
									<div className={classes.multFieldLine}>
										<Typography variant="h6">{i18n.t("whatsappModal.form.weekend.dayWeek")}: </Typography>
										<Field
											as={TextField}
											label={i18n.t("whatsappModal.form.startWork.name")}
											type="time"
											ampm={false}
											defaultValue="08:00"
											inputRef={startWorkRef}
											InputLabelProps={{shrink: true,}}
											inputProps={{step: 600,}}
											fullWidth
											name="startWork"
											error={touched.startWork && Boolean(errors.startWork)}
											helperText={touched.startWork && errors.startWork}
											variant="outlined"
											margin="dense"
											className={classes.hora}
										/>
										<Field
											as={TextField}
											label={i18n.t("whatsappModal.form.endWork.name")}
											type="time"
											ampm={false}
											defaultValue="17:00"
											inputRef={endWorkRef}
											InputLabelProps={{shrink: true,}}
											inputProps={{step: 600,}}
											fullWidth
											name="endWork"
											error={touched.endWork && Boolean(errors.endWork)}
											helperText={touched.endWork && errors.endWork}
											variant="outlined"
											margin="dense"
											className={classes.hora}
										/>
									</div>
									<div>
										<FormGroup
											aria-label="position"
											row
											sx={{
												width: {xs: 100,sm: 200,md: 300,lg: 600,xl: 700,},
											}}
											>
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isMon"
														checked={values.isMon}
														/>
													}
													label={i18n.t("whatsappModal.form.weekend.Monday")}
											/>										
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isTue"
														checked={values.isTue}
														/>
													}
													label={i18n.t("whatsappModal.form.weekend.Tuesday")}
											/>										
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isWed"
														checked={values.isWed}
														/>
													}
													label={i18n.t("whatsappModal.form.weekend.Wednesday")}
											/>										
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isThu"
														checked={values.isThu}
													/>
												}
												label={i18n.t("whatsappModal.form.weekend.Thursday")}
											/>										
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isFri"
														checked={values.isFri}
													/>
												}
												label={i18n.t("whatsappModal.form.weekend.Friday")}
											/>
										</FormGroup>
									</div>
									<div className={classes.multFieldLine}>
										<Typography variant="h6">{i18n.t("whatsappModal.form.weekend.endWeek")}: </Typography>
										<Field
											as={TextField}
											label={i18n.t("whatsappModal.form.startWork.name")}
											type="time"
											ampm={false}
											defaultValue="08:00"
											inputRef={startWeekRef}
											InputLabelProps={{shrink: true,}}
											inputProps={{step: 600,}}
											fullWidth
											name="startWeek"
											error={touched.startWeek && Boolean(errors.startWeek)}
											helperText={touched.startWeek && errors.startWeek}
											variant="outlined"
											margin="dense"
											className={classes.hora}
										/>
										<Field
											as={TextField}
											label={i18n.t("whatsappModal.form.endWork.name")}
											type="time"
											ampm={false}
											defaultValue="17:00"
											inputRef={endWeekRef}
											InputLabelProps={{shrink: true,}}
											inputProps={{step: 600,}}
											fullWidth
											name="endWeek"
											error={touched.endWeek && Boolean(errors.endWeek)}
											helperText={touched.endWeek && errors.endWeek}
											variant="outlined"
											margin="dense"
											className={classes.hora}
										/>
									</div>
									<div>
										<FormGroup
											aria-label="position"
											row
											sx={{
												width: {xs: 100,sm: 200,md: 300,lg: 600,xl: 700,},
											}}
											>
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isSat"
														checked={values.isSat}
													/>
												}
												label={i18n.t("whatsappModal.form.weekend.Saturday")}
											/>										
											<FormControlLabel
												control={
													<Field
														as={Checkbox}
														color="primary"
														name="isSun"
														checked={values.isSun}
													/>
												}
												label={i18n.t("whatsappModal.form.weekend.Sunday")}
											/>
										</FormGroup>									
									</div>
								</FormControl>
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.absenceMessage.name")}
										type="absenceMessage"
										multiline
										minRows={5}
										fullWidth
										name="absenceMessage"
										error={
											touched.absenceMessage && Boolean(errors.absenceMessage)
										}
										helperText={
											touched.absenceMessage && errors.absenceMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isTicketNPS"
												value={values.isTicketNPS}
												checked={values.isTicketNPS}
											/>
										}
									/>	
									<div className={classes.title}>
										<h4 className={classes.titleH2}>{i18n.t("whatsappModal.form.isTicketNPS.name")}
										<Tooltip title={i18n.t("whatsappModal.form.ticketNPSMessage.title")}>
											<IconButton aria-label="isTicketNPS">
												<HelpIcon />
											</IconButton>
										</Tooltip>
										</h4>
									</div>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.ticketNPSMessage.name")}
										type="ticketNpsMessage"
										multiline
										minRows={5}
										fullWidth
										name="ticketNpsMessage"
										error={
											touched.ticketNpsMessage && Boolean(errors.ticketNpsMessage)
										}
										helperText={
											touched.ticketNpsMessage && errors.ticketNpsMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<div className={classes.title}>
										<h4 className={classes.titleH2}>
											<IconButton aria-label="timeClosedTicketInNps">
												<CancelPresentationIcon/>
											</IconButton>
											{i18n.t("whatsappModal.form.timeClosedTicketInNps.name")}
											<Tooltip title={i18n.t("whatsappModal.form.timeClosedTicketInNps.title")}>
												<IconButton aria-label="timeClosedTicketInNps">
													<HelpIcon />
												</IconButton>
											</Tooltip>
										</h4>
									</div>									
									<div>
											<Field
												as="select"
												id="timeClosedTicketInNps"
												name="timeClosedTicketInNps"
												value={values.timeClosedTicketInNps}
												onChange={handleChange}
												className={classes.option}
											>
												<option value={10}>{i18n.t("settings.settings.timeCreateNewTicket.options.10")}</option>
												<option value={30}>{i18n.t("settings.settings.timeCreateNewTicket.options.30")}</option>
												<option value={60}>{i18n.t("settings.settings.timeCreateNewTicket.options.60")}</option>
												<option value={300}>{i18n.t("settings.settings.timeCreateNewTicket.options.300")}</option>
												<option value={1800}>{i18n.t("settings.settings.timeCreateNewTicket.options.1800")}</option>
												<option value={3600}>{i18n.t("settings.settings.timeCreateNewTicket.options.3600")}</option>
												<option value={7200}>{i18n.t("settings.settings.timeCreateNewTicket.options.7200")}</option>
												<option value={21600}>{i18n.t("settings.settings.timeCreateNewTicket.options.21600")}</option>
												<option value={43200}>{i18n.t("settings.settings.timeCreateNewTicket.options.43200")}</option>
											</Field>
									</div>								
								</div>								
								
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
});
