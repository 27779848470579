import React, { useContext, useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import { green } from "@material-ui/core/colors";
import {
	Button,
	makeStyles,
	Paper,
	TextField,
	Typography
} from "@material-ui/core";

import { Can } from "../../components/Can";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n.js";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { systemVars } from "../../../package.json";
import CopyToClipboard from "../../components/CopyToClipboard";

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "600px",
			flexWrap: "wrap",
		},
	  },
	container:{
		padding: "16px 24px",
	},
	mainPaper:{
		margin:"0 auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.up("sm")]: {
			minWidth: "600px",
		},
	},
	title: {
	},
	titleH2: {
		fontSize: "1.25rem",
		fontWeight: "500",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textCampaignsContainer: {
		width: "100%",
	},
	content: {
		padding: "0 24px 16px",
	},
	formActions: {
		flex: "0 0 auto",
		display: "flex",
		padding: "8px",
		alignItems: "center",
		justifyContent: "flex-end", 		
	},
	option: {
		display: "inline-flex",
		width: "100%",
		margin: "8px 0",
	},
	settingLabel: {
		padding: "5px 0",
	},
	settingOption: {
		marginLeft: "auto",
	},
	settingTextField: {
		margin: "0 0 0 auto",
		maxWidth: "109px",
	},
	divider: {
		margin: "16px 0",
		border: "none",
		height: "1px",
		width: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.12)",
	},

}));

const Settings = () => {
	const { user } = useContext(AuthContext);

	const classes = useStyles();
	const [settings, setSettings] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	const goToSupport = () => {
		window.open(systemVars.settingsSupportLink, '_blank');
	}

	return (
		<MainContainer>
			<MainHeader>
				<Title>{i18n.t("super.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button
						variant="contained"
						color="primary"
						onClick={goToSupport}
                        disabled
					>
						{i18n.t("settings.support")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>

			<Paper
			className={classes.mainPaper}
			variant="outlined"
			>
				<div className={classes.root}>  
					<div className={classes.container}>
						<div className={classes.title}>
							<h2 className={classes.titleH2}>{i18n.t("super.settings.title")}</h2>
						</div>
						<Can
							role={user.profile}
							perform="settings-super-items:edit"
							yes={() => (
							<>
								<div className={classes.divider}></div>
								<Typography variant="body1">
									{i18n.t("super.settings.description")}
								</Typography>
								<div className={classes.divider}></div>
									<Typography variant="body1">
										<span className={classes.titleH3}>{i18n.t("super.settings.server")}</span>
									</Typography>
								<div className={classes.option}>
									<TextField
										margin="dense"
										variant="outlined"
										label="Node JS Environment"
										fullWidth
										value={process.env.NODE_ENV}
										className={classes.settingTextFieldReadOnly}
										readOnly
									/>
								</div>
								<div className={classes.option}>
									<TextField
										margin="dense"
										variant="outlined"
										label="Backend URL"
										fullWidth
										value={process.env.REACT_APP_BACKEND_URL}
										className={classes.settingTextFieldReadOnly}
										readOnly
									/>
								</div>
								<div className={classes.option}>
									<TextField
										margin="dense"
										variant="outlined"
										label="Token Api"
										fullWidth
										value={settings && settings.length > 0 && getSettingValue("userApiToken")}
										className={classes.settingTextFieldReadOnly}
										readOnly
									/>
									<CopyToClipboard content={settings && settings.length > 0 && getSettingValue("userApiToken")} color="secondary" />
								</div>
								<div className={classes.divider}></div>	
									<Typography variant="body1">
										<span className={classes.titleH3}>{i18n.t("super.settings.limits")}</span>
									</Typography>
								<div className={classes.option}>
									<Typography variant="body1" >
										<span className={classes.settingLabel}>{i18n.t("settings.settings.limits.maxUsers")}</span>
									</Typography>
									<TextField
										margin="dense"
										variant="outlined"
										id="maxUsers-setting"
										name="maxUsers"
										value={
											settings && settings.length > 0 && getSettingValue("maxUsers")
										}
										className={classes.settingTextField}
										onChange={handleChangeSetting}
									/>
								</div>

								<div className={classes.option}>
									<Typography variant="body1">
										<span className={classes.settingLabel}>{i18n.t("settings.settings.limits.maxConnections")}</span>
									</Typography>
									
									<TextField
										margin="dense"
										variant="outlined"
										id="maxConnections-setting"
										name="maxConnections"
										value={
											settings && settings.length > 0 && getSettingValue("maxConnections")
										}
										className={classes.settingTextField}
										onChange={handleChangeSetting}
									/>
								</div>
							</>
							)}
						/>
					</div>
				</div>
			</Paper>

		</MainContainer>

	);
};

export default Settings;